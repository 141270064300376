/* eslint-disable max-len,react/jsx-props-no-spreading */
import React from 'react';
import Icon from '@ant-design/icons';

function OwnerSvg() {
  return (
    <svg x="0px" y="0px" viewBox="0 0 256 256" enableBackground="new 0 0 256 256" width="1em" height="1em">
      {/* <g> */}
      {/*  <g> */}
      {/*    <g> */}
      {/*      <path fill="currentColor" */}
      {/*            d="M93.1,10.3c-9.7,2.1-17.5,9.5-20,19c-0.9,3.4-0.9,9.5,0,13c4.2,16.2,22.2,24.2,37.4,16.5c3.1-1.6,7.1-5,9.1-7.9l1.4-2.1h4.9h5l2.2-3.9c2.5-4.2,3.5-5.2,5.6-5.2c2.2,0,3.2,1,5.8,5.5l2.3,4h2.7h2.7l2.5-4.3c2.6-4.5,3.4-5.2,5.6-5.2c2,0,3.2,1,5.6,5.2c1.2,2.1,2.4,3.8,2.6,3.8c1.1,0,4.3-2.5,5.6-4.2c1.4-2,1.4-2.1,1.4-8.1c0-3.3-0.1-6.6-0.3-7.3c-0.5-1.8-2.6-4.1-4.9-5.3c-1.9-1-2.3-1-25.7-1.2l-23.8-0.1l-2.1-2.9c-2.6-3.5-7.5-6.9-11.9-8.4C103.3,10.1,96.3,9.6,93.1,10.3z M94.7,26.5c5.5,2.8,7.4,8.9,4.5,14.4c-5.1,9.8-19.8,6-19.8-5C79.3,27.9,87.6,22.9,94.7,26.5z" /> */}
      {/*      <path fill="currentColor" */}
      {/*            d="M121.8,71.3c-24.6,4.5-38.5,30.7-28.2,53.2c4.9,10.7,13.8,18.2,25.4,21.1c4.9,1.3,13.2,1.3,18.1,0c13.6-3.5,23.3-12.9,27.4-26.6c1-3.5,1.3-5.1,1.3-9.9c0-3.8-0.2-6.8-0.8-8.9c-3.5-14.1-13.6-24.4-27.9-28.3C134.1,71.1,125,70.6,121.8,71.3z" /> */}
      {/*      <path fill="currentColor" */}
      {/*            d="M83.1,150.5c-6.4,2-10.2,7-10.6,13.6c-0.2,3.1,0.5,7.1,6,34.3c7.1,34.8,7,34.6,12.4,40c3.4,3.4,7.4,5.8,11.7,6.9c4.1,1,46.8,1,50.9,0c4.2-1.1,8.2-3.5,11.7-6.9c5.3-5.2,5.3-5.2,12.2-38.8c5.3-25.9,6-30,6-34.2c0-4.3-0.1-5-1.2-7.2c-1.5-2.9-4.4-5.6-7.5-7c-2-0.9-3-1-11.9-1h-9.7l-12.4,21.5c-6.8,11.9-12.5,21.6-12.7,21.6c-0.1,0-5.9-9.7-12.7-21.6L103,150l-8.9-0.1C87.9,149.9,84.5,150,83.1,150.5z" /> */}
      {/*    </g> */}
      {/*  </g> */}
      {/* </g> */}
    <g>
      <path
        fill="currentColor"
        d="M89.3,19.4c-4.2,0.6-4.9,1-6,3.6l-1,2.5L80,25.1c-5.8-0.9-5.5-0.9-8.8,1c-4.3,2.5-11.9,10.1-14.4,14.5c-2.7,4.5-4.6,9.5-5.6,14.6c-1.3,6-0.8,15.7,0.9,21.5c0.9,2.9,1.2,4.8,1,6c-0.4,2.5,0.6,7.9,2,10.9c1.4,2.9,4,5.4,6.3,5.8c1.4,0.2,1.7,0.7,2.8,3.2c2.1,4.9,5.5,10,9.3,13.8L77,120v3.5v3.5l-2.2,1.9c-2.4,2.1-5.9,3.4-24.6,9.1c-20.9,6.3-28.4,9.6-33.3,14.3c-5.6,5.4-7.3,10.6-6.8,20.8c0.2,5.3,1.5,15.5,2,16.3c0.4,0.8,16,7,24.6,9.8c13.7,4.5,28,7.9,40.2,9.3c2.6,0.3,3.5,0.2,3.3-0.1c-0.1-0.3-3.4-4.9-7.2-10.2l-7-9.7l4.1-6.9l4.1-6.9l49-0.2l49-0.2l3.7-4.5c3.6-4.4,3.7-4.6,3.4-6.5c-0.8-5.4-6-12-11.9-15.1c-4.8-2.6-13.9-5.9-26.2-9.6c-24-7.3-26-8.1-27.8-11c-0.6-1-0.9-2.4-0.9-4.2c0-2.6,0-2.7,2.2-4.3c3.6-2.8,8.3-8.9,11-14.4c1.6-3.3,2.7-4.9,3.2-4.9c0.4,0,1.8-0.6,3-1.4c1.7-1.1,2.6-2.1,3.8-4.7c1.4-2.9,1.6-3.8,1.9-8.8c0.2-3.6,0.7-6.9,1.4-9.5c1.6-5.6,1.7-16.5,0.1-22.3c-3.8-13.9-13.7-25.3-26.7-30.5C105.6,19.7,96,18.5,89.3,19.4z"
      />
      <path
        fill="currentColor"
        d="M202.2,161.1c-10,1.8-20.1,8.7-25,17.3l-1.9,3.2h-48h-48l-1.1,2.5c-0.6,1.4-1.1,2.7-1,2.7c0.1,0.1,22,0.2,48.7,0.2c51.2,0,49.1-0.1,48.2,2c-0.3,0.7-5,0.8-49,0.8c-26.8,0-48.7,0.1-48.7,0.2c0,0.1,3,3.9,6.8,8.2l6.8,8h2.9c2.6,0,3.2-0.2,7.1-2.7c2.4-1.4,4.8-2.7,5.5-2.7c0.7,0,3.1,1.4,5.7,3.2c4.5,3.2,7,4.2,8.9,3.7c0.5-0.1,3-2.2,5.5-4.6c3.9-3.6,4.8-4.2,6-4.1c1.3,0.1,1.5,0.4,2.6,3.4c1.4,3.9,2,4.1,4.8,1.6c2.4-2.2,4.1-2.2,6.6-0.1c6.7,5.7,7.4,6.1,9.6,6.1h2.2v5.1v5.1l2.8-1.9c4.5-3,7.8-4.5,10.8-4.8l2.8-0.3l2.8,4.3c8.8,13.5,22.3,20.7,35.8,19.1c15.7-1.9,28.3-12.9,32.4-28.3c1.4-5.4,1.4-13.9,0-19.3c-3.9-14.5-14.6-24.8-28.8-27.8C211.2,160.3,207,160.2,202.2,161.1z M231.4,189.7c5.8,3.6,6.5,12.3,1.2,16.9c-2.3,2.1-3.6,2.5-6.8,2.5c-9.6,0-13.9-11.9-6.7-18.3C222.8,187.6,227.2,187.2,231.4,189.7z"
      />
    </g>
    </svg>
  );
}

export function OwnerIcon(props: any) {
  return <Icon component={OwnerSvg} {...props} />;
}
